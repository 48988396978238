import React from 'react';
import styles from './Link.module.scss';
import { Node } from '../';
import {
  Text,
  IconChevron,
  Spacer,
  IconNetTVCable,
  IconNet,
} from 'dss-ui-library';
import { Link as NCSLink } from '@ncs-frontend-monorepo/content-library';

interface LinkProps {
  onClick(): void;
  node: Node;
  currentMaxDepth: number;
}

interface RenderLinkProps {
  node: Node;
  isLink?: boolean;
}

const RenderLink: React.FC<RenderLinkProps> = ({ node, isLink = false }) => {
  return (
    <div className={styles.link} data-e2e={`link-${node.pageId}`}>
      <div>
        {node.icon && (
          <div className={styles.icon}>
            <Spacer r={2}>
              {node.icon === 'nettvcable' && (
                <IconNetTVCable scale="medium" color="blue" />
              )}
              {node.icon === 'nettvinternet' && (
                <IconNet scale="medium" color="blue" />
              )}
            </Spacer>
            <div>
              <Text appearance="t6_2">{node.title}</Text>
              <Spacer t={1} block />
              <Text appearance="t6">{node.text}</Text>
            </div>
          </div>
        )}
        {!node.icon && <Text appearance="t5">{node.title}</Text>}
      </div>
      {!isLink && (
        <IconChevron
          small
          rotation="right"
          color="blue"
          width={20}
          height={20}
        />
      )}
    </div>
  );
};

export const Link: React.FC<LinkProps> = ({
  onClick,
  node,
  currentMaxDepth,
}) => {
  return (
    <>
      {node.level === currentMaxDepth && (
        <NCSLink
          color="black"
          url={node.url}
          title={node.title}
          e2e={`link-${node.pageId}`}
          key={`link-${node.pageId}`}
        >
          <RenderLink node={node} isLink />
        </NCSLink>
      )}
      {node.level !== currentMaxDepth && (
        <div onClick={onClick}>
          <RenderLink node={node} />
        </div>
      )}
    </>
  );
};
