import React, { useEffect, useState } from 'react';
import styles from './TeaserWrapper.module.scss';
import { Media } from '@ncs-frontend-monorepo/utils';
import { Grid, Spacer, Separator, Slider } from 'dss-ui-library';
import { Teaser } from '../../../Teaser';

interface TeasersProps {
  teasers: Teaser[];
}

export const TeaserWrapper: React.FC<TeasersProps> = ({ teasers }) => {
  const [showSlider, setShowSlider] = useState<boolean>(false);

  useEffect(() => {
    setShowSlider(true);
  }, []);

  return (
    <>
      <Media lessThan="s">
        <div className={styles.mobileContainer}>
          {showSlider && (
            <Slider settings={{ arrows: false, infinite: false, dots: false }}>
              {teasers.slice(0, 2).map((teaser, teaserIdx) => (
                <Teaser {...teaser} key={`teaser-${teaserIdx}`} />
              ))}
            </Slider>
          )}
        </div>
      </Media>
      <Media greaterThanOrEqual="s">
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Separator color="superlight-grey" height={2} />
              <Spacer t={3} block />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {teasers.slice(0, 2).map((teaser, teaserIdx) => (
              <Grid.Column xs={6} key={`teaser-${teaserIdx}`}>
                <Teaser {...teaser} />
                <Spacer t={3} block />
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </Media>
    </>
  );
};
