import { DropDownColumnProps, DropDownRowProps } from '../../../interfaces';
import { Teaser } from '../Teaser';
import { apiUrl } from '../../../utils';
import { fetcher } from '@ncs-frontend-monorepo/utils';

type DropDownType = 'ModuleMenuDropdownColumn' | 'ModuleMenuDropdownRow';
export interface FetchedDropDown {
  category: number;
  content: {
    component: DropDownType;
    id: number;
    props: DropDownColumnProps | DropDownRowProps;
  };
}

export const getComponent = (dropDowns: FetchedDropDown[]): DropDownType =>
  dropDowns.reduce(
    (pv, dropDown: FetchedDropDown) => dropDown?.content?.component,
    'ModuleMenuDropdownColumn',
  );

export const getTeasers = (dropDowns: FetchedDropDown[]): Teaser[] =>
  dropDowns.reduce(
    (pv: Teaser[], dropDown: FetchedDropDown) =>
      pv.concat(dropDown?.content?.props?.teasers || []),
    [],
  );

interface LinkInfo {
  textLink?: string;
  textLinkTracking?: string;
}

export const getLinkInfo = (dropDowns: FetchedDropDown[]): LinkInfo =>
  dropDowns.reduce(
    (_, dropDown: FetchedDropDown) => ({
      textLink: dropDown?.content?.props?.textLink,
      textLinkTracking: dropDown?.content?.props?.textLinkTracking,
    }),
    {},
  );

export const convertDropDownColumn = (dropDowns: FetchedDropDown[]) => {
  return dropDowns
    .reduce(
      (pv, dropDown: FetchedDropDown) =>
        pv.concat(
          (dropDown?.content?.props as DropDownColumnProps).links || [],
        ),
      [],
    )
    .map((link) => ({
      title: link.headline,
      subpages: link.items.map((item) => ({
        title: item.linkText,
        url: item.link.url,
        pageId: item.link.pageId,
      })),
    }));
};

export const convertDropDownRow = (dropDowns: FetchedDropDown[]) => {
  const columns = dropDowns.reduce(
    (pv, dropDown: FetchedDropDown) =>
      pv.concat((dropDown.content?.props as DropDownRowProps).columns || []),
    [],
  );

  const convertRowIconOrLinks = (row) => {
    return row.icon
      ? {
          title: row.headline,
          icon: row.icon,
          text: row.text,
          url: row.link.url,
          pageId: row.link.pageId,
        }
      : {
          title: row.title,
          url: row.url,
          pageId: row.pageId,
        };
  };

  const nodes = columns[0]?.rows.map((row, rowIdx) => {
    return {
      ...convertRowIconOrLinks(row),
      subpages: columns.slice(1).map((column, columnIdx, columns) => {
        const nodes = columns[columnIdx].rows[rowIdx];
        const links = nodes.links.length
          ? nodes.links.map((link) => convertRowIconOrLinks(link))
          : [];
        const rootLink = nodes.icon ? [convertRowIconOrLinks(nodes)] : [];
        const subpages = rootLink.concat(links);
        return {
          title: column.headline,
          subpages,
        };
      }),
    };
  });

  return nodes;
};

export const fetchDropDownById = async (
  pageId: number,
): Promise<FetchedDropDown> => {
  const url = `${apiUrl()}/content/pksite-dropdown-${pageId}`;

  const result = await fetcher(url);
  return {
    category: pageId,
    content: result.content,
  };
};
