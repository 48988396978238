import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import styles from './MobileProductMenu.module.scss';
import { SitemapEntry } from '../../../interfaces';
import { Tunnel, convert, limitByLevel, updateNodes, Node } from './Tunnel';
import {
  convertDropDownColumn,
  convertDropDownRow,
  getTeasers,
  getLinkInfo,
  getComponent,
  FetchedDropDown,
  fetchDropDownById,
} from './dropdown';
import { useRouter } from 'next/router';

interface MobileProductMenuProps {
  isOpen: boolean;
  categoryItems: SitemapEntry[];
}

interface TreePosition {
  page: number;
  pageId: number;
  currentTreeIndex: number;
}

export const MobileProductMenu: React.FC<MobileProductMenuProps> = React.memo(
  ({ isOpen, categoryItems }) => {
    const nodeRef = useRef(null);
    const [fetchedDropDowns, setFetchedDropDowns] = useState<FetchedDropDown[]>(
      [],
    );
    const router = useRouter();
    const initialTrees = categoryItems.map((category) =>
      limitByLevel(convert(category), 1),
    );
    const [trees, setTrees] = useState(initialTrees);
    const [treePosition, setTreePosition] = useState<TreePosition>({
      page: 0,
      pageId: 0,
      currentTreeIndex: 0,
    });

    const currentDropDown = fetchedDropDowns.filter(
      (dropDown: FetchedDropDown) => dropDown.category === treePosition.pageId,
    );

    const updateTreeWithDropDown = (
      tree: Node,
      dropDown: FetchedDropDown[],
      pageId: number,
    ) => {
      const nextTree: Node[] =
        getComponent(dropDown) === 'ModuleMenuDropdownColumn'
          ? convertDropDownColumn(dropDown)
          : convertDropDownRow(dropDown);

      // place the new dropdowns trees in the current tree
      const newTree = convert(updateNodes(tree, pageId, nextTree));

      return newTree;
    };

    const onNextPage = async (
      page: number,
      pageId: number,
      currentTreeIndex: number,
    ) => {
      // start fetching dropdowns
      if (page === 0) {
        const dropDownAlreadyFetched =
          fetchedDropDowns.filter(
            (dropDown: FetchedDropDown) => dropDown.category === pageId,
          ).length > 0;
        if (!dropDownAlreadyFetched) {
          const fetchedDropDown = await fetchDropDownById(pageId);
          setFetchedDropDowns([...fetchedDropDowns, fetchedDropDown]);
        }
        setTreePosition({
          page,
          pageId,
          currentTreeIndex,
        });
      }
    };

    const updateBodyClass = (isOpen: boolean) => {
      isOpen
        ? document
            .querySelector('body')
            .classList.add(styles.productNavigationOpen)
        : document
            .querySelector('body')
            .classList.remove(styles.productNavigationOpen);
    };

    useEffect(() => {
      const component = getComponent(currentDropDown);

      if (component) {
        const newTree = updateTreeWithDropDown(
          trees[treePosition.currentTreeIndex],
          currentDropDown,
          treePosition.pageId,
        );

        // trees "Produkte" or "Hilfe & Service"
        const newTrees = [...trees];
        newTrees[treePosition.currentTreeIndex] = newTree;

        setTrees(newTrees);
      }
    }, [treePosition]);

    useEffect(() => {
      async function prefetchDropdowns() {
        return Promise.all(
          categoryItems.reduce((promises, { subpages = [] }) => {
            return [
              ...promises,
              ...subpages.map((subpage) => fetchDropDownById(subpage.pageId)),
            ];
          }, []),
        );
      }
      if (isOpen && fetchedDropDowns.length === 0) {
        prefetchDropdowns()
          .then((dropdowns) => setFetchedDropDowns(dropdowns))
          .catch((e) => console.warn(e));
      }
      updateBodyClass(isOpen);
    }, [isOpen, setFetchedDropDowns, categoryItems, fetchedDropDowns.length]);

    useEffect(() => {
      const handleRouteChange = () => {
        updateBodyClass(false);
      };
      router.events.on('routeChangeStart', handleRouteChange);
      return () => {
        router.events.off('routeChangeStart', handleRouteChange);
      };
    }, [router.events]);

    return (
      <>
        <nav
          aria-label="product-categories"
          className={cx(styles.productNavigation, {
            [styles.open]: !!isOpen,
          })}
        >
          <Tunnel
            trees={trees}
            teasers={getTeasers(currentDropDown)}
            linkInfo={getLinkInfo(currentDropDown)}
            onNextPage={onNextPage}
          />
        </nav>
        <CSSTransition
          nodeRef={nodeRef}
          in={isOpen}
          timeout={500}
          unmountOnExit={false}
          classNames={{
            enterDone: styles.overlayEnterDone,
            enterActive: styles.overlayEnterActive,
            exitActive: styles.overlayExitActive,
          }}
        >
          <div className={styles.overlay} />
        </CSSTransition>
      </>
    );
  },
);
