import React, { Fragment, useState, useEffect } from 'react';
import styles from './Tunnel.module.scss';
import { Spacer, Tab, Tabs, Text, Grid, Separator } from 'dss-ui-library';
import { SitemapEntry } from '../../../../interfaces';
import { getTreeData, Node, getMaxDepth } from './';
import { SlideInPanel } from './SlideInPanel';
import { TransitionGroup } from 'react-transition-group';
import { HistoryBackButton } from './HistoryBackButton';
import { TeaserWrapper } from './TeaserWrapper';
import { Link } from './Link';
import { Teaser } from '../../Teaser';

interface TunnelProps {
  trees: Node[];
  teasers: Teaser[];
  // eslint-disable-next-line
  linkInfo: any; // not yet, later
  onNextPage(page: number, pageId: number, currentTreeIndex: number): void;
}

interface TunnelPage {
  page: number;
  pageId: number;
  title: string;
}

export const Tunnel: React.FC<TunnelProps> = React.memo(
  ({ trees, teasers, onNextPage }) => {
    const [mainTab, setMainTab] = useState('tab-0');
    const currentTreeIndex = parseInt(mainTab.slice('tab-'.length));
    const currentPageTree = trees[currentTreeIndex];

    const startPage: TunnelPage = {
      page: 0,
      pageId: currentPageTree.pageId,
      title: currentPageTree.title,
    };

    const [history, setHistory] = useState<TunnelPage[]>([startPage]);

    const historyBack = () => setHistory(history.slice(0, history.length - 1));

    const nextPage = async (page: number, pageId: number, title: string) => {
      // w8 that data has been fetched, before start animation
      await onNextPage(page, pageId, currentTreeIndex);
      setHistory(
        history.concat({
          page: page + 1,
          pageId,
          title,
        }),
      );
    };

    useEffect(() => {
      const newStartPage: TunnelPage = {
        page: 0,
        pageId: currentPageTree.pageId,
        title: currentPageTree.title,
      };

      setHistory([newStartPage]);
    }, [mainTab]);

    return (
      <div className={styles.tunnel}>
        {/* first non animated page */}
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Spacer t={2} block />
              <Tabs
                e2e="tunnel-main-categories"
                selected={mainTab}
                onSelected={setMainTab}
              >
                {trees.map((entry: SitemapEntry, categoryIdx: number) => {
                  return (
                    <Tab id={`tab-${categoryIdx}`} key={`tab-${categoryIdx}`}>
                      <Text appearance="t5_2">{entry.title}</Text>
                    </Tab>
                  );
                })}
              </Tabs>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Separator color="superlight-grey" height={2} />
        {history.slice(0, 1).map((tunnel: TunnelPage) => (
          <div className={styles.page} key={`tunnel-${tunnel.pageId}`}>
            <div>
              {getTreeData(currentPageTree, tunnel.page + 1, tunnel.pageId).map(
                (node) => {
                  return (
                    <Fragment key={`node-${node.pageId}`}>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column>
                            <Spacer t={3} block />
                            <Link
                              node={node}
                              currentMaxDepth={9999}
                              onClick={() =>
                                nextPage(tunnel.page, node.pageId, node.title)
                              }
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Fragment>
                  );
                },
              )}
            </div>
          </div>
        ))}
        {/* animated next pages */}
        <TransitionGroup component={null}>
          {history.slice(1).map((tunnel: TunnelPage) => (
            <SlideInPanel key={`tunnel-${tunnel.pageId}`}>
              <Spacer t={2} block />
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <HistoryBackButton
                      onClick={historyBack}
                      title={history[tunnel.page - 1].title}
                      e2e={`link-${history[tunnel.page - 1].pageId}`}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Spacer t={3} block />
              <div className={styles.page}>
                <div>
                  {getTreeData(
                    currentPageTree,
                    tunnel.page + 1,
                    tunnel.pageId,
                  ).map((node, nodeIdx) => {
                    return (
                      <Fragment key={`node-${node.pageId}`}>
                        <Spacer l={2} block>
                          <Grid>
                            <Grid.Row>
                              <Grid.Column>
                                {nodeIdx === 0 && tunnel.page > 0 && (
                                  <Text appearance="t5_2">
                                    {node.parentTitle}
                                  </Text>
                                )}
                                <Spacer t={3} block />
                                <Link
                                  node={node}
                                  currentMaxDepth={getMaxDepth(
                                    currentPageTree,
                                    history[1].pageId,
                                  )}
                                  onClick={() =>
                                    nextPage(
                                      tunnel.page,
                                      node.pageId,
                                      node.title,
                                    )
                                  }
                                />
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Spacer>
                      </Fragment>
                    );
                  })}
                </div>
                {teasers.length > 0 && tunnel.page === 1 && (
                  <TeaserWrapper teasers={teasers} />
                )}
              </div>
            </SlideInPanel>
          ))}
        </TransitionGroup>
      </div>
    );
  },
);
