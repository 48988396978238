import { SitemapEntry } from '../../../../interfaces';

export interface Node extends SitemapEntry {
  level?: number;
  parent?: number;
  parentTitle?: string;
  // optional tv properties
  icon?: string;
  text?: string;
}

// TODO replace with loadash.cloneDeep, but fine atm
export const cloneDeep = (node: Node) => JSON.parse(JSON.stringify(node));

export const traverse = (node: Node, callback: (node: Node) => void) => {
  const queue = [];
  queue.unshift(node);
  while (queue.length) {
    const item = queue.shift();
    callback(item);
    for (let i = 0; i < item?.subpages?.length; i++) {
      queue.push(item.subpages[i]);
    }
  }
};

export const getMaxPageId = (node: Node) => {
  let pageId = 0;
  traverse(node, (item) => {
    if (pageId < item.pageId) pageId = item.pageId;
  });
  return pageId;
};

export const convert = (node: Node) => {
  const tree = cloneDeep(node);
  tree.level = 0;
  traverse(tree, (item: Node) => {
    const level = item.level;
    if (!item.pageId) {
      item.pageId = getMaxPageId(tree) + 1;
    }
    for (let i = 0; i < item?.subpages?.length; i++) {
      const next: Node = item.subpages[i];
      next.level = level + 1;
      next.parent = item.pageId;
      next.parentTitle = item.title;
    }
  });
  return tree;
};

export const flatTree = (node: Node): Node[] => {
  const nodes: Node[] = [];
  traverse(node, (item: Node) => nodes.push(item));
  return nodes;
};

export const limitByLevel = (node: Node, levelLimit: number) => {
  const tree = cloneDeep(node);
  traverse(tree, (item: Node) => {
    if (item.level >= levelLimit) {
      item.subpages = [];
    }
  });
  return tree;
};

export const getMaxDepth = (tree: Node, pageId: number): number => {
  const nodes = flatTree(tree).filter((node: Node) => node.pageId === pageId);
  return flatTree(nodes[0]).reduce(
    (pv: number, cv: Node, _) => (pv < cv.level ? cv.level : pv),
    1,
  );
};

export const updateNodes = (node: Node, pageId: number, nodes: Node[]) => {
  const tree = cloneDeep(node);
  traverse(tree, (item: Node) => {
    // when no children set
    if (item.pageId === pageId && item.subpages.length === 0) {
      item.subpages = item.subpages.concat(nodes);
    }
    // when children are set (not the case atm)
  });
  return tree;
};

export const getTreeData = (
  tree: Node,
  level: number,
  pageId: number,
): Node[] =>
  flatTree(tree).filter(
    (node) => node.level === level && node.parent === pageId,
  );
