import { FC, ReactNode, useRef } from 'react';
import styles from './SlideInPanel.module.scss';
import { CSSTransition } from 'react-transition-group';

export interface SlideInPanelProps {
  children?: ReactNode;
}

export const SlideInPanel: FC<SlideInPanelProps> = ({ children, ...props }) => {
  const nodeRef = useRef(null);
  return (
    <CSSTransition
      {...props}
      nodeRef={nodeRef}
      timeout={{
        exit: 300,
      }}
      classNames={{
        enter: styles.sliderEnter,
        enterDone: styles.sliderEnterDone,
        exit: styles.sliderExit,
        exitDone: styles.sliderExitDone,
      }}
    >
      <div ref={nodeRef} className={styles.slider}>
        {children}
      </div>
    </CSSTransition>
  );
};
