import React from 'react';
import { Text, Spacer, Separator, IconChevron } from 'dss-ui-library';
import styles from './HistoryBackButton.module.scss';

interface HistoryBackProps {
  onClick(): void;
  title: string;
  e2e: string;
}

export const HistoryBackButton: React.FC<HistoryBackProps> = ({
  onClick,
  title,
  e2e,
}) => {
  return (
    <div className={styles.historyBack} onClick={onClick} data-e2e={e2e}>
      <Spacer l={1}>
        <Text appearance="t5_2" color="blue">
          {title}
        </Text>
        <Separator color="white" height={2} />
      </Spacer>
      <div className={styles.indent}>
        <IconChevron
          small
          rotation="left"
          color="blue"
          width={20}
          height={20}
        />
      </div>
    </div>
  );
};
